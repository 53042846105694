import React from 'react';

export const SidebarData = [
  {
    title: 'Research',
    path: '/research',
    // icon: <i className="fa-solid fa-magnifying-glass"></i>,
    iconClosed: <i className="fa-solid fa-angle-down"></i>,
    iconOpened: <i className="fa-solid fa-angle-up"></i>,

    subNav: [
      {
        title: 'Conceptual Development Lab',
        path: '/conceptual-development-lab',
        // icon: <i className="fa-solid fa-graduation-cap"></i>
      },
      {
        title: 'Digital Technologies and Education Lab',
        path: '/digital-technologies-and-education-lab',
        // icon: <i className="fa-solid fa-person-chalkboard"></i>
      },
      {
        title: 'STEM Design-Based Research Lab',
        path: '/stem-design-based-research-lab',
        // icon: <i className="fa-solid fa-paintbrush"></i>
      },
      {
        title: 'Youth and Educator SEL Lab',
        path: '/youth-and-educator-sel-lab',
        // icon: <i className="fa-solid fa-paintbrush"></i>
      }
      // {
      //   title: 'Career Development',
      //   path: '/research/career-development',
      //   icon: <i className="fa-solid fa-graduation-cap"></i>
      // },
      // {
      //   title: 'Cognitive Development',
      //   path: '/research/cognitive-development',
      //   icon: <i className="fa-solid fa-person-chalkboard"></i>
      // },
      // {
      //   title: 'Creativity',
      //   path: '/research/creativity',
      //   icon: <i className="fa-solid fa-paintbrush"></i>
      // },
      // {
      //   title: 'Equity & Access',
      //   path: '/research/equity-and-access',
      //   icon: <i className="fa-solid fa-scale-balanced"></i>
      // },
      // {
      //   title: 'Group Learning',
      //   path: '/research/group-learning',
      //   icon: <i className="fa-solid fa-people-line"></i>
      // },
      // {
      //   title: 'Motivation',
      //   path: '/research/motivation',
      //   icon: <i className="fa-solid fa-lightbulb"></i>
      // },
      // {
      //   title: 'Research Methods',
      //   path: '/research/research-methods',
      //   icon: <i className="fa-regular fa-chart-bar"></i>
      // },
      // {
      //   title: 'Social & Cultural Development',
      //   path: '/research/social-and-cultural-development',
      //   icon: <i className="fa-regular fa-face-smile"></i>
      // },
      // {
      //   title: 'Technology & Learning',
      //   path: '/research/technology-and-learning',
      //   icon: <i className="fa-solid fa-computer"></i>
      // }
    ]
  },
  {
    title: 'Programs',
    path: '/programs',
    // icon: <i className="fa-solid fa-chalkboard-user"></i>,
    iconClosed: <i className="fa-solid fa-angle-down"></i>,
    iconOpened: <i className="fa-solid fa-angle-up"></i>,

    subNav: [
      {
        title: 'Career-Connected Learning and Life Skills',
        path: '/interest-areas/career-connected-learning-and-life-skills',
        // icon: <i className="fa-solid fa-palette"></i>
      },
      {
        title: 'Creativity, Design, and Play',
        path: '/interest-areas/creativity-design-and-play',
        // icon: <i className="fa-solid fa-briefcase"></i>
      },
      {
        title: 'Health and Well-Being',
        path: '/interest-areas/health-and-well-being',
        // icon: <i className="fa-solid fa-person-walking"></i>
      },
      {
        title: 'Technology, Media, and Information Literacy',
        path: '/interest-areas/technology-media-and-information-literacy',
        // icon: <i className="fa-solid fa-headphones-simple"></i>
      },
      // {
      //   title: 'Artistic Design',
      //   path: '/programs/artistic-design',
      //   icon: <i className="fa-solid fa-palette"></i>
      // },
      // {
      //   title: 'Career and Technical Education',
      //   path: '/programs/career-and-technical-education',
      //   icon: <i className="fa-solid fa-briefcase"></i>
      // },
      // {
      //   title: 'Dance',
      //   path: '/programs/dance',
      //   icon: <i className="fa-solid fa-person-walking"></i>
      // },
      // {
      //   title: 'Digital Audio',
      //   path: '/programs/digital-audio',
      //   icon: <i className="fa-solid fa-headphones-simple"></i>
      // },
      // {
      //   title: 'Digital Civics',
      //   path: '/programs/digital-civics',
      //   icon: <i className="fa-solid fa-tachograph-digital"></i>
      // },
      // {
      //   title: 'Dramatic Arts',
      //   path: '/programs/dramatic-arts',
      //   icon: <i className="fa-solid fa-masks-theater"></i>
      // },
      // {
      //   title: 'EdTech',
      //   path: '/programs/edtech',
      //   icon: <i className="fa-solid fa-laptop-code"></i>
      // },
      // {
      //   title: 'Film',
      //   path: '/programs/film',
      //   icon: <i className="fa-solid fa-film"></i>
      // },
      // {
      //   title: 'Financial Education',
      //   path: '/programs/financial-education',
      //   icon: <i className="fa-solid fa-chart-line"></i>
      // },
      // {
      //   title: 'Games & Learning',
      //   path: '/programs/games-and-learning',
      //   icon: <i className="fa-solid fa-dice"></i>
      // },
      // {
      //   title: 'Health Education',
      //   path: '/programs/health-education',
      //   icon: <i className="fa-solid fa-laptop-medical"></i>
      // },
      // {
      //   title: 'Internships',
      //   path: '/programs/internships',
      //   icon: <i className="fa-solid fa-id-card-clip"></i>
      // },
      // {
      //   title: 'Interventions',
      //   path: '/programs/interventions',
      //   icon: <i className="fa-solid fa-hand-holding-heart"></i>
      // },
      // {
      //   title: 'Marine Science',
      //   path: '/programs/marine-science',
      //   icon: <i className="fa-solid fa-otter"></i>
      // },
      // {
      //   title: 'STEM',
      //   path: '/programs/stem',
      //   icon: <i className="fa-solid fa-flask"></i>
      // }
    ]
  },
  {
    title: 'Philanthropy',
    path: '/philanthropy',
    // icon: <i className="fa-solid fa-hand-holding-heart"></i>
    // iconClosed: <RiIcons.RiArrowDownSFill />,
    // iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: 'Interest Areas',
    path: '/interest-areas',
    // icon: <i className="fa-solid fa-hand-holding-heart"></i>
    // iconClosed: <RiIcons.RiArrowDownSFill />,
    // iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: 'Resources',
    path: '/resources',
    // icon: <i className="fa-solid fa-magnifying-glass"></i>,
    iconClosed: <i className="fa-solid fa-angle-down"></i>,
    iconOpened: <i className="fa-solid fa-angle-up"></i>,

    subNav: [
      {
        title: 'All Resources & Publications',
        path: '/resources',
        // icon: <i className="fa-solid fa-handshake-simple"></i>
        // iconClosed: <RiIcons.RiArrowDownSFill />,
        // iconOpened: <RiIcons.RiArrowUpSFill />,
      },
      {
        title: 'Resources for Teens & Young Adults',
        path: '/resources-for-teens',
        // icon: <i className="fa-solid fa-handshake-simple"></i>
        // iconClosed: <RiIcons.RiArrowDownSFill />,
        // iconOpened: <RiIcons.RiArrowUpSFill />,
      },
      {
        title: 'Resources for Educators',
        path: '/resources-for-educators',
        // icon: <i className="fa-solid fa-handshake-simple"></i>
        // iconClosed: <RiIcons.RiArrowDownSFill />,
        // iconOpened: <RiIcons.RiArrowUpSFill />,
      },
      {
        title: 'Resources for Researchers',
        path: '/research',
        // icon: <i className="fa-solid fa-handshake-simple"></i>
        // iconClosed: <RiIcons.RiArrowDownSFill />,
        // iconOpened: <RiIcons.RiArrowUpSFill />,
      }
    ]
  },
  {
    title: 'Stories',
    path: '/stories',
    // icon: <i className="fa-solid fa-hand-holding-heart"></i>
    iconClosed: <i className="fa-solid fa-angle-down"></i>,
    iconOpened: <i className="fa-solid fa-angle-up"></i>,

    subNav: [
      {
        title: 'All Stories',
        path: '/stories',
      },
      {
        title: 'Blogs',
        path: '/stories?cat=690',
      },
      {
        title: 'Research News',
        path: '/stories?cat=689',
      },
      {
        title: 'Philanthropy Stories',
        path: '/stories?cat=35213',
      },
      {
        title: 'Press Releases',
        path: '/stories?cat=691',
      },
    ]
  },
  {
    title: "News/Media",
    path: '/news',
    // icon: <i className="fa-solid fa-comment"></i>
    iconClosed: <i className="fa-solid fa-angle-down"></i>,
    iconOpened: <i className="fa-solid fa-angle-up"></i>,

    subNav: [
      {
        title: 'All News and Media',
        path: '/news',
      },
      {
        title: 'Press Resources',
        path: '/press-resources',
      },
      {
        title: 'Past Newsletters',
        path: '/news/newsletters',
      },
      {
        title: 'foundry10 in the News',
        path: '/news/press',
      },
    ]
  },
  {
    title: 'About',
    path: '/what-we-do',
    // icon: <i className="fa-solid fa-circle-info"></i>,
    iconClosed: <i className="fa-solid fa-angle-down"></i>,
    iconOpened: <i className="fa-solid fa-angle-up"></i>,

    subNav: [
      {
        title: 'What We Do',
        path: '/what-we-do',
        // icon: <i className="fa-solid fa-circle-info"></i>
      },
      {
        title: '2022-2023 Annual Report',
        path: '/resources/2022-2023-annual-report-leaning-into-strengths-and-looking-forward',
        // icon: <i className="fa-solid fa-square-poll-vertical"></i>
      },
      {
        title: 'Equity Commitment',
        path: '/equity',
        // icon: <i className="fa-solid fa-circle-info"></i>
      },
      {
        title: 'Our Team',
        path: '/about/team',
        // icon: <i className="fa-solid fa-people-group"></i>
      },
      {
        title: 'Careers',
        path: '/careers',
        // icon: <i className="fa-solid fa-briefcase"></i>
      },
      {
        title: 'How We Started',
        path: '/how-we-started',
        // icon: <i className="fa-solid fa-book-open"></i>
      }, 
      {
        title: 'Contact',
        path: '/contact',
        // icon: <i className="fa-solid fa-envelope"></i>
      }
    ]
  }
];